import React from 'react';
import get from 'lodash/get';
import classnames from 'classnames';
import MuiButton from '@mui/material/Button';
import AppleSignin from 'react-apple-signin-auth';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Skeleton from 'components/uiLibrary/Loaders/Skeleton';
import useTracking from 'components/Globals/Analytics';
import { useRouter } from 'next/router';

import { useTranslation } from 'src/i18n';
import { setCookie } from 'utils/cookie';
import { useDialogs } from 'utils/hooks/useDialogs';
import { nextPageQueryName } from 'utils/auth';
import { useSocialLoginURLs } from 'utils/queries/socialMedia/loginURLs';
import config from 'config/config.json';

import { BASE_PAGE_ROUTES, TP } from 'constants/index';
import { LOGIN_TYPE } from 'constants/cookieConstants';
import classes from './SocialLoginButtons.module.scss';

const SkeletonLoader = ({ styleConfig = {}, showSingle = false }) => {
  const skeleton = (
    <div className={classnames(classes.linkButton, styleConfig.skeletonButtonClass)}>
      <Skeleton variant="rectangular" className={classnames(classes.skeletonIcon, styleConfig.skeletonIconClass)} />
      <Skeleton
        variant="text"
        width="80%"
        className={classnames(classes.skeletonText, styleConfig.skeletonTextClass)}
      />
    </div>
  );
  if (showSingle) {
    return <div className={classnames(classes.socialLoginButtonsWrap, styleConfig.wrapperClass)}>{skeleton}</div>;
  }
  return (
    <div className={classnames(classes.socialLoginButtonsWrap, styleConfig.wrapperClass)}>
      {[1, 2, 3].map(key => (
        <React.Fragment key={key}>{skeleton}</React.Fragment>
      ))}
    </div>
  );
};

const SocialSignInButtons = ({
  showGoogle = true,
  showFacebook = true,
  showApple = true,
  styleConfig = {},
  isReturningUser = false,
  returningEmail = '',
  trackingData = {},
}) => {
  const { t } = useTranslation('NS_REGISTRATION_V4');
  const router = useRouter();
  const { isLoginDialog } = useDialogs();
  const track = useTracking();

  const currentPath = router.asPath;

  const isAuthPage = currentPath.includes(BASE_PAGE_ROUTES.LOGIN) || currentPath.includes(BASE_PAGE_ROUTES.REGISTER);

  const nextPageFromQuery = get(router, `query.${nextPageQueryName}`);

  const nextPage = isAuthPage ? nextPageFromQuery || BASE_PAGE_ROUTES.HOME : currentPath;

  const url = nextPage;

  const setSocialLoginType = loginType => {
    setCookie(LOGIN_TYPE, loginType);
  };
  const { data: responseLinks, isLoading } = useSocialLoginURLs();
  const { google, facebook, apple } = get(responseLinks, 'data', {});

  const GOOGLE = 'Google';
  const FACEBOOK = 'Facebook';
  const APPLE = 'Apple';

  const state = JSON.stringify({
    popupSource: isLoginDialog?.source,
    nextPage: url,
  });
  const encodedState = encodeURIComponent(state);

  const getGoogleLink = () => {
    const redirectUrl = `${config.FRONTBASE_URL}/auth/google`;
    return `${google}&redirect_uri=${redirectUrl}&state=${encodedState}`;
  };

  const getFacebookLink = () => {
    const redirectUrl = `${config.FRONTBASE_URL}/auth/facebook`;
    return `${facebook}&redirect_uri=${redirectUrl}&state=${encodedState}`;
  };

  const renderButton = (platform, getLink, icon) => (
    <MuiButton
      className={classnames(classes.linkButton, styleConfig?.buttonClass)}
      type="button"
      href={getLink()}
      onClick={() => {
        track.click({ ...trackingData, subComponent: `${platform.toLowerCase()}CTA`, meta: { isReturningUser } });
        setSocialLoginType(platform.toLowerCase());
      }}
      endIcon={
        returningEmail && styleConfig?.emailText && <SpriteIcon icon="arrow_forward" className={classes.forwardIcon} />
      }
    >
      <div>
        <span className={classes.buttonText}>
          <SpriteIcon icon={icon} className={classnames(classes.socialIcon, styleConfig.iconClass)} />
          {t(`${TP}.FN_AUTH_LOGIN_OR_REGISTER`, { authType: platform })}
        </span>
        {isReturningUser && returningEmail && styleConfig?.emailText && (
          <span className={classnames(styleConfig.emailText)}>{returningEmail}</span>
        )}
      </div>
    </MuiButton>
  );

  if (isLoading) {
    return <SkeletonLoader styleConfig={styleConfig} showSingle={isReturningUser && returningEmail} />;
  }

  return (
    <div className={classnames(classes.socialLoginButtonsWrap, styleConfig.wrapperClass)}>
      {showGoogle && google && renderButton(GOOGLE, getGoogleLink, 'google_ico', 'google')}
      {showFacebook && facebook && renderButton(FACEBOOK, getFacebookLink, 'facebook:hover', 'facebook')}
      {showApple && apple && (
        <AppleSignin
          authOptions={{
            clientId: apple,
            scope: 'email name',
            redirectURI: `${config.FRONTBASE_URL}/auth/apple`,
            state: encodedState,
            nonce: 'nonce',
            usePopup: false,
          }}
          uiType="dark"
          className={classnames('apple-auth-btn', styleConfig.appleButtonClass)}
          noDefaultStyle={false}
          buttonExtraChildren="Continue with Apple"
          skipScript={false}
          iconProp={{ style: { marginTop: '10px' } }}
          render={props => (
            <MuiButton
              className={classnames(classes.linkButton, styleConfig?.buttonClass)}
              type="button"
              {...props}
              onClick={() => {
                if (typeof props?.onClick === 'function') {
                  props.onClick();
                }
                track.click({ ...trackingData, subComponent: 'appleCTA', meta: { isReturningUser } });
              }}
              endIcon={
                returningEmail &&
                styleConfig?.emailText && <SpriteIcon icon="arrow_forward" className={classes.forwardIcon} />
              }
            >
              <div>
                <span className={classes.buttonText}>
                  <SpriteIcon icon="apple_ico" className={classnames(classes.socialIcon, styleConfig.iconClass)} />
                  {t(`${TP}.FN_AUTH_LOGIN_OR_REGISTER`, { authType: APPLE })}
                </span>
                {isReturningUser && returningEmail && styleConfig?.emailText && (
                  <span className={classnames(styleConfig.emailText)}>{returningEmail}</span>
                )}
              </div>
            </MuiButton>
          )}
        />
      )}
    </div>
  );
};

export default SocialSignInButtons;
